.com-logo-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 0 40px 0;

  background-color: white;
}

.course-main-div {
  position: relative;
  margin: auto;
  background-color: black;
  border-radius: 1rem;
  border: none;
  padding: 1rem;
  width: 90%;
  box-shadow: rgba(53, 51, 48, 1) 0px 0px 30px 2px;
  margin-bottom: 120px;
  transition: transform 0.5s ease;
}
.courese-inner-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.tick-sym {
  border-radius: 50%;
  margin-right: 10px;
}

.left-div,
.right-div {
  flex: 1;
  padding: 10px;
}

.center-line {
  border: 0.1px solid gray;
  margin: 20px auto;
}

.left-title,
.right-title {
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.left-div ul,
.right-div ul {
  list-style-type: none;
  padding: 0;
}

.left-div li,
.right-div li {
  color: rgb(219, 209, 209);
  font-size: 20px;
  padding: 15px 0;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: left;

  font-family: "Dosis", sans-serif;
  font-weight: 600;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  width: 100%;
  overflow: hidden;
  padding: 0;
  /* background-color: rgb(255, 251, 243); */
  background-color: white;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 15s slide infinite linear;
}

.logos-slide img {
  height: 150px;
  margin: 0 40px;
}

.img2-logo {
  border-radius: 3rem;
}
