.sec-3 {
  padding: 40px 20px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features-title {
  color: navy;
  font-size: 30px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;

  text-align: center;
  padding: 20px 10px;
}
.sec-3-center-line {
  width: 90px;
  border-bottom: 0.1px solid rgb(129, 106, 57);
  margin: auto;
  margin-bottom: 40px;
}
.feature-list {
  width: 100%;
  max-width: 1000px;
  margin: 1rem auto;
  padding: 1rem;
  background-color: white;
  color: navy;

  border-radius: 1rem;
  box-shadow: 0 0 20px 10px rgba(44, 44, 44, 1);
  letter-spacing: 1px;
}

.sec-3 ul {
  list-style-type: none;
  padding: 0;
}

.sec-3 li {
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  font-family: "Dosis", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.more-content-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  color: black;
  margin-top: 4rem;
}

.course-pdf {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #3498db;
  color: #ffffff;
  border-radius: 5px;
  border: 2px solid #3498db;
  cursor: pointer;
  transition: transform 0.5s ease, letter-spacing 0.5s ease;
}

.course-pdf:hover {
  background-color: #2980b9;
  border-color: #2980b9;
  color: #ffffff;
  transform: translateY(10%);
  letter-spacing: 1px;
}

.brochureImg-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
}
.brochure-img {
  max-width: 100%;
  border: 2px solid white;
  box-shadow: 0 0 20px 10px rgba(244, 242, 242, 0.5);
  height: auto;
}
.pricing-btn {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topics {
  width: 90%;
  text-align: center;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 1rem;
}
.topics h1 {
  color: navy;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
  padding: 1rem;
}
.topics ul {
  width: 100%;

  padding: 1rem;
  margin-top: 30px;
  text-align: center;
  column-gap: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: decimal;
  flex-wrap: wrap;
}
.topics ul li {
  width: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #333;
  font-size: 19px;
  margin-bottom: 1rem;

  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  text-align: center;
  transition: transform 0.2s ease;
}
.topics ul li:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 765px) {
  .feature-list {
    width: 100%;
  }

  .more-content-div {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    padding: 0;
  }
  .brochureImg-div {
    width: 100%;
  }
  .more-content1 {
    font-size: 28px;

    margin-top: 0;

    padding: 20px 0;
  }
  .topics {
    width: 100%;
  }
  .topics h1 {
    font-size: 18px;
    padding: 2rem 0.5rem 1rem 0.5rem;
    margin: 0;
  }
  /* .topics ul li {
    width: 100%;
  } */
}
