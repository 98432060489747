.official-web {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.web-h1 {
  padding: 1rem 1rem 2rem 1rem;
  letter-spacing: 2px;
}
.web-h3 {
  margin-bottom: 10px;
}
.web-h1,
.web-h3 {
  color: goldenrod;
  font-family: "Fjalla One", sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1.5px;
}
.contact-a {
  text-decoration: none;
  color: rgb(215, 196, 154);
}
.contact-a:hover {
  text-decoration: underline;
}
.web-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  border-radius: 1rem;
  border: 1px solid rgb(255, 199, 0);
  padding: 4rem 2rem 4rem 2rem;
  width: 90%;
  min-height: 80vh;
  text-align: center;
  box-shadow: rgba(53, 51, 48, 0.48) 0px 0px 50px 2px;
}
.contact-p1 {
  color: rgb(215, 196, 154);
  font-size: 18px;
}
.contact-p2 {
  color: rgb(255, 251, 243);
  padding: 1rem;
}
.contact-loc {
  animation: none;
  box-shadow: none;
  margin-bottom: 1.5rem;
  display: inline-block;
  padding: 10px 20px;
  background-color: #4285f4;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.contact-loc:hover {
  background-color: #0c7bdc;
  transform: scale(1.05);
}
.contact-p3 {
  margin-bottom: 1rem;
}
.whatsappButton {
  animation: none;
  display: flex;
  align-items: center;
  justify-items: left;
  padding: 10px 20px;
  background-color: #25d366;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.contact-a2 {
  text-decoration: none;
  display: inline-block;
  margin: 1rem 0;
}
.whatsappButton:hover {
  background-color: #128c7e;
  transform: scale(1.05);
}

.whatsappButton img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}
.disclaimer-div {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f8f8;
}

.disclaimer-div h1 {
  color: red;
  font-size: 24px;
  margin-bottom: 10px;
}

.disclaimer-div p {
  color: #555;
  font-size: 16px;
  line-height: 1.5;
}

@media screen and (max-width: 900px) {
  .official-web {
    padding: 0 10px;
  }
  .official-web h1 {
    padding-top: 2rem;
  }
  .web-h3 {
    color: goldenrod;
    font-family: "Fjalla One", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1.5px;
  }
  .web-div {
    width: 100%;

    text-align: center;
    box-shadow: rgba(53, 51, 48, 0.48) 0px 0px 50px 2px;
  }
  .web-div button {
    padding: 15px 16px;
    font-size: 13px;
  }

  .whatsappButton {
    font-size: 14px;
  }
  .contact-loc {
    font-size: 14px;
  }
  .disclaimer-div {
    padding: 0px 15px 15px 15px;
  }

  .disclaimer-div h1 {
    font-size: 20px;
  }

  .disclaimer-div p {
    font-size: 14px;
  }
}
