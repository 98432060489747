.sec-6 {
  background-color: white;
  padding: 90px 0px 30px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.google-review-div {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
}
.google-review-title {
  color: maroon;
  font-size: 30px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
  margin-bottom: 30px;
}
.google-div {
  margin: 0 auto;
  width: 50%;
  height: 60vh;
}

.google-div iframe {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  
}

.text-review {
  animation: slide-1 1000s linear infinite;
  border: 1px solid white;
  border-radius: 1rem;
  text-align: center;
  width: 50%;
  padding: 1rem;
  background-color: black;
  box-sizing: border-box;
  min-height: fit-content;
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.text-review-2 {
  animation: slide-2 1000s linear infinite;
  width: 50%;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
  min-height:fit-content;
  flex: 1; 
  border: 1px solid white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.star-img {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.star-img img {
  width: 100%;
  object-fit: cover;
}

.review {
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 400px;
  margin: auto;
  white-space: normal; 
}

.reviewer-name {
  color: rgb(0, 255, 247);
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}

@keyframes slide-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -10000%
    ); 
  }
}

@keyframes slide-2 {
  0% {
    transform: translateX(-8600%);
  }
  100% {
    transform: translateX(
      0
    );
  }
}
.reviews-upper-div{
  border-bottom: 3px dashed white;
 
}
.reviews-upper-div,
.reviews-lower-div {
  overflow: hidden;
  background-color:black;
  
  position: relative;
  display: flex;
  width: 100%;
  min-height: fit-content;
  white-space: nowrap;
  column-gap: 3rem;
  padding: 20px 50px;
  
}

.reviews-upper-div:before,
.reviews-upper-div:after,
.reviews-lower-div:before,
.reviews-lower-div:after {
  position: absolute;
  top: 0;
  width: 350px;
  height: 100%;
  content: "";
  z-index: 2;
}

.reviews-upper-div:before,
.reviews-lower-div:before {
  left: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    black
  );
}

.reviews-upper-div:after,
.reviews-lower-div:after {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    black
  );
}

.reviews-upper-div:hover .text-review,
.reviews-lower-div:hover .text-review-2 {
  animation-play-state: paused;
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .google-review-div {
    width: 90%;
    
  }
  .google-div {
    margin: 0 auto;
    width: 80%;
    height: 40vh;
  }
}

@media screen and (max-width: 900px) {
  .sec-6 {
    padding: 60px 0px;
  }

  .google-review-div {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }

  .google-review-title {
    font-size: 1.5rem;
    margin-bottom: 25px;
  }

  .google-div {
    width: 100%;
   
    margin-bottom: 15px;
  }

  

  .text-review {
    width: 100%;
    min-height: fit-content;
  }
  .review {
    width: 220px;
    font-size: 10px;
    font-weight: 400;
  }
  .reviews-upper-div:before,
  .reviews-upper-div:after,
  .reviews-lower-div:before,
  .reviews-lower-div:after {
    width: 50px;
  }
  .text-review-2 {
    
    width: 100%;
    height: 15vh;
    
    justify-content: center;
    
  }
}
