.sec-4 {
  background-color: white;
  padding: 40px 20px;
}

.training-info-div {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.training-heading {
  color: black;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
}
.sec-4-center-line {
  width: 120px;
  border-bottom: 0.1px solid black;
  margin: auto;
}

.training-targets {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-top: 50px;
  column-count: 2;
  column-gap: 10rem;
}

.training-targets li {
  width: 16rem;
  color: white;
  font-size: 19px;
  margin-bottom: 1rem;
  background-color: maroon;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  text-align: center;
  transition: transform 0.2s ease;
}

.training-targets li:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 900px) {
  .sec-4 {
    padding: 60px 20px;
  }

  .training-info-div {
    max-width: 100%;
  }

  .training-heading {
    font-size: 20px;
  }

  .training-targets {
    column-count: 1;
    column-gap: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .training-targets li {
    font-size: 16px;
  }
}
