.sec-1 {
  background-color: black;
  height: 100vh;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec-1-div {
  padding: 20px;
  text-align: center;

  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 90vh;
  border-radius: 4rem;
  background-color: white;
  box-shadow: 0 0 20px 10px rgba(44, 44, 44, 1);
}
.logo-img {
  width: 30%;
  max-width: 100%;
  display: flex;

  justify-content: space-evenly;
  align-items: center;
}

.logo-img img {
  max-width: 100%;
  width: 100px;
  height: auto;

  object-fit: cover;
}
.title-div {
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.company-name {
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 2px;
  color: black;
}
.company-quote {
  font-size: 23px;
  color: maroon;
}

.quotes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 20vh;
}
.quotes-center-line {
  width: 10rem;
  border-bottom: 0.1px solid orangered;
}
.motto-quote {
  position: relative;
  /* color: rgb(215, 196, 154); */
  color: #333333;
  font-size: 1.5em;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
}

.quotes-2 {
  color: maroon;
  font-family: "Fjalla One", sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1.5px;

  text-align: center;
}

.offer-end-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.offer-end-main h3 {
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-size: 1.2em;
  color: gray;
}

.offer-end-div {
  display: flex;
}

.time-box {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  background-color: #333333;
  padding: 5px 20px;
  border-radius: 5px;
  text-align: center;
  border: 3px solid goldenrod;
  color: bisque;
}

.min,
.sec {
  color: white;
  font-size: 1.5em;
}
.minutes,
.seconds {
  font-size: 3em;
  font-weight: 400;
}
.offer-timer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.offer-button {
  text-decoration: none;
  display: inline-block;
  margin: 1rem 0;
}
.offer-button button:hover {
  transform: scale(1.05);
}

.offer-button button {
  padding: 25px 45px;
  display: flex;
  align-items: center;
  justify-items: left;
  border: none;
  font-size: 1em;
  margin-top: 15px;
  letter-spacing: 1px;
  font-weight: 700;
  animation: anime 2s linear infinite;
  color: white;
  background: linear-gradient(-45deg, #fc6076, #ff9a44, #ef9d43, #e75516);
  z-index: 1;
  cursor: pointer;
  border-radius: 1rem;
  transition: transform 0.5s ease;
}

.strike-rate {
  text-decoration: line-through;
}

@keyframes anime {
  0% {
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 40px rgba(255, 165, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8);
  }
}

@media screen and (max-width: 767px) {
  .sec-1 {
    padding: 10px;
  }
  .sec-1-div {
    padding: 10px;
    overflow: auto;
    border-radius: 2rem;
  }
  .logo-img {
    margin-left: 0px;
    width: fit-content;
    flex-direction: column;
  }

  .quotes {
    width: 100%;
  }
  .company-name {
    font-size: 30px;
    letter-spacing: 3px;
    margin-top: 5px;
  }
  .company-quote {
    font-size: 18px;
  }
  .quotes-center {
    position: absolute;
    left: 50%;
    top: -1.9%;
    text-align: center;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    background-color: crimson;
    padding: 0.5rem;
    border-radius: 4rem;
  }
  .quotes-2 {
    box-sizing: border-box;

    padding: 10px;
    letter-spacing: 1.5px;
    font-size: 22px;
  }
  .motto-quote {
    font-size: 1.4em;
  }

  .course-main-div {
    width: 95%;
    padding: 3rem 0.5rem 2rem 2rem;
  }
  .courese-inner-div {
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .left-div,
  .right-div {
    flex: 1;
    padding: 0;
  }

  .tick-sym {
    margin-right: 10px;
  }

  .center-line {
    width: 45%;
    border-bottom: 1px solid white;
    margin: 15px auto;
  }

  .offer-timer-div {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  .time-box {
    font-size: 10px;

    padding: 5px 20px;
  }
  .offer-button {
    margin: 0 0 0.5rem 0;
  }

  .offer-button button {
    padding: 10px 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .offer-timer-div {
    width: 100%;
    padding: 0 10px;
    flex-direction: column;
  }
}
